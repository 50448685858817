@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital@0;1&display=swap');
html{
  background-color: #e7e7e7;
  
  background-repeat: no-repeat;
  background-position: center;

}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;

  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer{
  width: 100%;
  background-color: #000000;
}

h2{
  font-size: 20px;
}


input{
  display: none;
}

#display{
  display: block;
}

button{
  background-color: #FFFF;
  border: none;
  color: #000000;
  width: 300px;
  height: 55px;
  font-size: 22px;
  cursor: pointer;
  margin-bottom: 50px;
  transition: ease-in 0.4s;
}

button:hover{
  background-color: #FF4C00;
  color: #dadbdb;
}

.p-small{
  font-size: 12px;

  margin-top: 10px;
}

.center-div{
  text-align: center;
  width: 100%;
  z-index: 10;
}

.global-container{
    position: relative;
    width: 1440px;
    display: flex;
    flex-direction: column;
}

#container-row{
  display: flex;
  flex-direction: row;

}

.big-h1{
  font-size: 50px;
  margin-bottom: 50px;
  margin-top: 20px;
  transition: height 0.5s linear;
}

.form-h1{
  font-size: 50px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.black-button{
  color: white;
  background-color: #000000;
  width: 300px;
  height: 55px;
  margin-top: 75px;
}

#tilbake-button{
  background-color: #6E6E6E;
  margin-right: 55px;
}

video{
  height: 100%;
  z-index: 10px;
  margin: 0 auto;
}

#font68{
  font-size: 50px;
}

#spaces-information-mobile{
  display: none;
}
#spaces-information-desktop{
  display: block;
}

/****** Menu ******/
.menu-outer{
  width: 100%;
  display: flex;
  height: 138px;
  background-color: white;
  position: fixed;
  z-index: 10;
  text-align: center;
  box-shadow: 20px 0px 8px 0px;
}

.menu{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    height: 138px;
    width: 1440px;
  
    background-color: white;

}
.info-big-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 940px;
  margin: 0 auto;
}

.info-container{
  display: flex;
  flex-direction: row;
}
.link_menu{
  float: right;
  margin-right: 100px;
  position: relative;
  bottom: 20px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
.link_menu_right{
  float: right;
  margin-right: 200px;
  position: relative;
  bottom: 20px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}

.logo_menu{
  position: relative;
  cursor: pointer;
  top: 15px;
  right: px;
  margin-left: 20px;
  z-index: 10;
}

#pristilbud{
  display: block;
  background-color: #FF4C00;
  padding: 10px;
  color: white;
  position: relative;
  bottom: 10px;
  border-radius: 5px;
}

/****** Footer ******/

.column{
  float: left;
  width: 33.33%;
  background-color: #000000;
  height: 180px;
  color: white;
}
.row{
  width: 1140px;
  display: flex;
  margin: 0 auto;
  text-align: center;
}

#smaller-button{
  width: 180px;
  height: 33px;
  font-size: 13px;
}

.left-footer{
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}
.right-footer{
  width: 50%;
  
}

.image-footer{
  width: 117px;
  height: 68px;
}
.footer-foot{
  text-align: center;
  width: 100%;
  height: 60px;
  background-color: #000000;
}
/****** Pristilbud ******/

.pristilbud-background{
  width: 100%;
  min-height: 100vh;
  max-height: 300vh;
}


.transparent-background{
  top: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 500%;
  height: 725px;
  transition: height 0.5s linear;
  
}

.inside-background{
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  top: 150px;
}

.sliding-column{
  width: 20%;
  text-align: center;
}

.progress-background{
  position: absolute;
  transform: translate(-50%);
  left: 50vw;
  margin-top: 50px;
}

.input{
  width: 700px;
  height: 55px;
  font-size: 22px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.216);
  border-style: solid;
  border-color: #FF4C00;
}


.input2{
  width: 400px;
  height: 45px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.216);
  border-style: solid;
  border-color: #FF4C00;
}

.p-button-label{
  cursor: pointer;

}

#upload-column{
  height: fit-content;
}

.p-button{
  margin: 20px;
}

.errorInformation{
  position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 21px;
}

.errorInformationOut {
  margin: 10px 10px;
}

#TextArea{
  height: 300px;
}

/****** HomePage ******/

.welcome-container{
  width: 100%;
  background-color: #000000ad;
  text-align: center;
  padding-top: 150px;
  padding-bottom: 150px;
}

.welcome-container-inner{
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  margin: auto;
}

.video-class{
  width: 700px;
  height: 250px;
}

.welcome-text{
  color: white;
  text-align: left;
  margin: 20px;
}
.welcome-inner{
  width: 50%;
  max-width: 1440px;
  margin: auto;
}
.welcome-image{
  width: 50%;
  margin-right: 25px;
}

.weclome-text{
  text-align: center;
}

.top-button{
  margin-top: 50px;
  float: left;
  margin: 20px;
}


@media screen and (max-width: 985px) {
  .welcome-container-inner{
    position: relative;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    margin: auto
  }

  .welcome-image{
    position: relative;
    margin: auto;
    top: 100px;
    width: 90%;
  }

  .welcome-text{
    width: 80%;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .top-button{
    position: relative;
    left: 50%;
    transform: translate(-57%);

  }
}

@media screen and (max-width: 1222px) {
  
  .welcome-inner{
    width: 100%;
    text-align: center;
  }
}

.video-player{
  height: 100%;
}

.information-container{
  width: 100%;
  max-width: 1440px;
  height: 250px;
  margin: 0 auto;
}

#information-column{
  width: 50%;
  height: 100%; 
  background-color: transparent;
  text-align: center;
  color: black;
  justify-content: center;
}

.informationH1{
  font-size: 55px;
  position: relative;
}

.informationP{
  font-size: 18px;
  position: relative;
  top: 40%;
  transform: translate(0,-50%);
  text-align: left;
  line-height: 29px;
}

#service-container{
  background-image: none;
  height: 600px;
  background-color: #ffffff;
}

.image-service{
  float: left;
  width: 98%;
  object-fit: cover;
}

#information-column1 {
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  color: black;
  justify-content: center;
  background-image: url("./assets/desktop/pdr.png");
  background-repeat: no-repeat;
  background-position: center 45%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#information-column2{
  width: 50%;
  height: 100%; 
  background-color: transparent;
  text-align: center;
  color: black;
  justify-content: center;
  background-image: url("./assets/desktop/oppretting.png");
  background-size: 99%;
  background-repeat: no-repeat;
  background-position: right;
}

@media screen and (max-width: 600px) {
  #information-column2{
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.over-information-column{
  width: 99%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 0.70);
}
.h1-over-information-column{
  font-size: 56px;
  color: #ffffff;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

#p-over-information-column{
  position: relative;
  display: block;
  width: fit-content;
  color: #ffffff;
  font-size: 18px;
  margin: auto;
}

.buttons-information{
  display: flex;
  flex-direction: row;
  margin: auto 0;
}

@media screen and (max-width:700px) {
  .buttons-information {
    flex-direction: column;
  }
}

.text-container{
  margin: 0 auto;
  text-align: left;
}

.service-container{
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
}

.contact-left{
  background-color: transparent;
  width: 50%;
  text-align: center;
  justify-content: center;
}

.contact-right{
  background-color: transparent;
  width: 50%;
  text-align: center;
  margin: 100px auto;
}

.about-top-background{
  position: relative; 
  width: 1440px;
  height: 773px;
  background-image: url("./assets/desktop/kontoret.png");
  background-repeat: no-repeat;
  left: 50%;
  transform: translate(-50%);
}

.about-top-inner{
  position: relative;
  width: 50%;
  left: 50%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column ;
}

.points-container{
  margin: 0 auto;
  width: 1440px;
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: center;
  grid-auto-rows: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.name-tag{
  width: 100%;
  height: 50px;
  background-color: #000000;
  position: relative;
  bottom: 55px;
  color: #dadbdb;
  text-align: center;
  vertical-align: center;
}

#small-picture:hover .name-tag{
  background-color: #FF4C00;
  transition: ease-in-out 0.4s;
}

.grid-prosjekter{
  margin: 0 auto;
  width: 1440px;
  display: grid;
  grid-template-columns: 720px 720px;
  padding-top: 200px;
  text-align: center;
  grid-auto-rows: auto;
}

.button-information-one{
  margin: 70px auto 0px;
  margin-top: 70px;
}

.button-information-two{
  margin: 0px auto 30px;
  margin-top: 30px;
}

.om-oss-text{
  text-align: left;
  font-size: 20px;
  position: relative; 
  left: 50px;
  width: 680px;
}


/****** Pristilbud ******/

.upload-input{
  display: flex;
  background-color:#000000;
  color: #e7e7e7;
  text-align: center;
  font-size: 23px;
  width: 700px;
  height: 50px;
  margin: 0 auto;
  cursor: pointer;
  transition: ease-in 0.4s;
}

.upload-input:hover{
  background-color: #FF4C00;
}
.upload-inner-container{
  background-color: #6e6e6e6b;
  width: 700px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row ;
}

.delete-outer{
  width: 130px;
  height: 45px;
  overflow: hidden;
  background-color: #e7e7e7;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;

}
.velg-filer{
  position: relative;
  left:50%;
  transform: translate(-50%);
}

.maps{
  height: 50vh;
  width: 1440px;
  margin: 0 auto;
}

#om-oss-text1{
  display: block;
}
#om-oss-text2{
  display: none;
}
#ernest{
  font-size: 30px;
}
.ernest2{
  font-size: 50px;
  margin-top: 200px;
}


@media screen and (max-width: 1420px){
    .information-container{
      display: flex;
      flex-direction: column;
      position: relative;
      left: 50%;
      transform: translate(-25%);
    }
    
    #information-column{
      width: 100%;
    }
    
    .informationP{
      line-height: 28px;
      width: fit-content;
      margin: auto;
      right: 25%;
    }

    #service-container{
      left:25%;
      background-color: transparent;
    }
    #information-column1{
      width: 100%;
      margin-bottom: 20px;
      background-position: center;
    }
    #information-column2{
      width: 100%;
      background-position: center;
    }
  
    #service-container{
      height: 1100px;
    }

    .points-container{
      width: 100%;
    }
    .maps{
      width: 100%;
    }
    #om-oss-text1{
      display: none;
    }
    #om-oss-text2{
      display: block;
      left:100px;
    }
    .big-h1{
      position: relative;
      left:100px;
      text-align: left;
    }
    .menu{
      width: 100%;
    }
    
}

@media screen and (max-width: 1300px){
  #background{
    width: 100%;

  }
  .service-container{
    flex-direction: column;
  }
  .contact-left{
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  .contact-right{
    width: 50%;
    
  }
}

@media screen and (max-width: 1100px){
  #ernest{
    font-size: 25px ;
  }
}


@media screen and (max-width: 1000px){
  .about-top-background{
    width: 100%;
    height: 1400px;
  }
  .about-top-inner{
    transform: translate(-50%);
    top: 800px;
  }
  .points-container{
    grid-template-columns: auto;
  }
  .big-h1{
    left: -100px;
  }
  #om-oss-text2{
    left: -100px;
  }
  #container-row{
    width: 476px;
  }
  .shorter-row{
    width: auto;
  }
  .contact-right{
    margin: auto;
    margin-bottom: 100px;
  }

}


@media screen and (max-width: 966px){
  #font68{
    font-size: 58px;
  }
  .ernest2{
    font-size: 45px;
    margin-top: 50px; 
  }
  
}

@media screen and (max-width: 764px){
  .ernest2{
    width: 406px;
    font-size: 45px;
  }
}

@media screen and (max-width: 700px){
 #ernest{
  font-size: 20px;
  position: relative;
  top: 5px;
 } 
}

@media screen and (max-width: 653px){
  .big-h1{
    font-size: 40px;
    width: 263px;
  }
}

@media screen and (max-width: 624px) {
  #ernest{
    right: 100px;
  }
  #ernest3{
    position: relative;
    right: 100px;
  }
  .ernest2{
    position: relative;
    right: 100px;
  }
  .eller-kontakt{
    position: relative;
    right: 100px;
  }
  .input2{
    position: relative;
    right: 100px;
  }
  .contact-label{
    position: relative;
    right: 100px;
  }
  .send-information{
    right: 100px;
  }
}


@media screen and (max-width: 615px){

    #spaces-information-mobile{
      display: block;
      margin-bottom: 50px;
    }
    #spaces-information-desktop{
      display: none;
    }
}

@media screen and (max-width: 550px){
  .h1-over-information-column{
    font-size: 40px;
  }
  #p-over-information-column{
    text-align: left; 
    font-size: 18px;
    line-height: 20px;
  }
  .button-information-one{
    margin: auto ;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .button-information-two{
    margin-top: 0px;
    margin: 40px ;
  }
  .about-top-background{
    left: 50%;
    background-position-x: center;
    width: 100%;
    background-image: url("./assets/mobile/kontoret.png");
    
  }
  .about-top-inner{
    transform: translate(-50%);
    top: 500px;
  }
  .about-top-background{
    height: 1000px;
  }
}


@media screen and (max-width: 470px){
  .informationH1{
    font-size: 40px;
  }
  #ernest{
    right: 70px;
  }
  #ernest3{
    position: relative;
    right: 70px;
  }
  .ernest2{
    position: relative;
    right: 120px;
    font-size: 40px;
  }
  .eller-kontakt{
    font-size:18px;
  }


}

@media screen and (max-width: 420px){
  .big-h1{
    left: -75px;
  }
  #om-oss-text2{
    left: -75px;
    font-size: 17px;
  }
  #ernest{
    font-size: 17px;
  }
}


@media screen and (max-width: 400px){
  .informationP{
    font-size: 16px;
  }
  .button-information-one{
    width: 250px;
  }
  .button-information-two{
    width: 250px;
  }
}


@media screen and (max-width: 444px){
  #font68{
    font-size: 48px;
  }
  #p-over-information-column{
    font-size: 15px;
  }
}
@media screen and (max-width: 344px){
  #font68{
    font-size: 38px;
  }
  #om-oss-text2{
    font-size:15px ;
  }
}



.mobileNav{
  display: none;
  position: absolute;
  width: 90%;
  top: 33px;
}

.fa-bars{
  float: right;
  width: 30px;
  height: 30px;
  background-image: url("./assets/mobile/close.png");
  margin: 0 auto;
}
.fa-times{
  float: right;
  background-image: url("./assets/mobile/exit.png");
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

@media screen and (max-width: 986px){
  .mobileNav{
    display: block;
  }
  .info-big-container{
    display: none;
  }
  .vector-line{
    display: none;
  }
  
  .menu{
    height: 85px;
  }
  .menu-outer{
    height: 85px;
  }
  .logo_menu{
    right: 0px;
  }
  .link_menu{
    margin-top: 50px;
    font-size: 25px;
  }
  .link_menu_right{
    margin-top: 50px;
    font-size: 25px;
  }
  #navbar{
    display: none;
    transition: ease-in-out 0.4s;
    
  }
  #navbar.active{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 85px;
    width: 100%;
    height: 100vh;
    background-color:#e7e7e7f7;
    transition: ease-in-out 0.4s;
  }

  li{
    position: relative;
    left: 40%;
    
    text-align: left;
  }

  #no-mobile{
    display: none;
  }
  #middle-mobile{
    width: 100%;
  }

}

@media screen and (max-width: 770px){
  
  .input{
    width: 300px;
  }
  .buttons-form{
    display: flex;
    flex-direction: column;
  }
  .black-button{
    margin: 10px auto;
  }
  #tilbake-button{
    margin:auto;
  }

  .upload-input{
    width: 90%;
  }
  .upload-inner-container{
    width: 90%;
  }
}

@media screen and (max-width: 530px){
  .progress-bar{
    width: 300px;
  }
} 

@media screen and (max-width: 345px){
  .input{
    width: 200px;
    font-size: 15px;
  }
  .progress-bar{
    width: 250px;
  }
  .form-h1{
    font-size: 40px;
  }
}

#form-submitted{
  display: none;
}

.before-after{
  width: 80%;
  margin: 20px auto;
}
.before-after-text{
  position: relative;
  bottom: 79px;
}
.for{
  display: block;
  width: 20%;
  height: 75px;
  text-align: center;
  font-size: 50px;
  color:#FF4C00;
  background-color: white;
  box-shadow: #000000;
}

#etter{
  float:right;
  position:relative;
  bottom: 75px;
}

.prosjekt-text{
  width: 80%;
  margin: 20px auto;
  font-size: 18px;
  line-height: 25px;
}

.prosjekt-pictures-container{
  margin: auto;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 100px;
  grid-auto-rows: auto;
}

.item{
  margin: 7px 7px;
}
.item-picture{
  width: 100%;
}

.projsekter-title-image{
  width: 100%;
  height: 70vh;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 100px;
  top: 120px;  
  background-image: url("assets/desktop/MgBig.png");
  background-repeat: no-repeat;
  background-position: center
}

.prosjekter-title{
  color: #ffffff;
  font-size: 68px;
  position: absolute;
  left:50%;
  bottom: 50%;
  transform: translate(-50%);
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.442);
}

@media screen and (max-width:1200px) {
  .for{
    height: 50px;
    font-size: 40px;
  }
  .before-after-text{
    bottom: 54px;
  }
  #etter{
    bottom: 50px;
  }
}

@media screen and (max-width: 700px) { 
  .before-after{
    width: 100%;
  }
  .for{
    height: 40px;
    font-size: 30px;
  }
  .before-after-text{
    bottom: 44px;
  }
  #etter{
    bottom: 40px;
  }
}

.checkbox-container{
  margin-top: 25px;
  margin-bottom:25px;
}

.CheckboxRoot {
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--black-a7);
}

.Label {
  color: rgb(0, 0, 0);
  padding-left: 15px;
  font-size: 15px;
  line-height: 1;
}

.muligheter{
  font-size: 50px;
  position: relative;
  top: 50px;
}

@media screen and (max-width: 610px){
  .information-container{
    height: 500px;
  }
}

.eller-kontakt{
  font-size: 30px;
}

.send-information{
  display: block;
  position:relative;
  width: 200px;
  height: 50px;
  margin: auto;
  margin-top: 50px;
  top: 25px;
}

.form-contact{
  width: 603px;
}

@media screen and (max-width: 900px){
  .eller-kontakt{
    font-size: 23px;
  }
}

@media screen and (max-width: 470px){
  
  .eller-kontakt{
    font-size:15px;
    right: 80px;
  }
  .contact-label{
    right: 80px;
  }
  .input2{
    width: 300px;
    right: 80px;
  }
  .form-contact{
    width: 400px;
  }


}

.thank-you-message{
  display: block;
  width: fit-content;
  margin: auto;
  margin-top: 100px;
  text-align: center;
}
#upload-button{
  display: none;
}

.p-about-us{
  margin: 0 auto;
  text-align: left;
  width: 230px;
  line-height: 22px;
}

.background-article{
  background-image: url("./assets/desktop/background-pdr-article.png");
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  background-position: right;
}

.lakkrens-article{
  background-image: url("./assets/desktop/background-lakkrens.png");
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  background-position: right;
}

.rens-article{
  background-image: url("./assets/desktop/rens-bil.png");
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  background-position: right;
}

.detailing-article{
  background-image: url("./assets/desktop/detailing.png");
  width: 100%;
  height: 400px;
  max-width: 800px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  background-position: right;
}

.main-section-article{
  width: 80%;
  max-width: 800px;
  margin: auto;
  font-size: 1.2rem;
  line-height: 2.3rem;
  font-weight: 100;
}

.heading-1{
  font-size: 32px;
}
.heading-2{
  font-size: 30px;

}

strong{
  font-weight: 600;
}

#image-background{
  
  background-repeat: no-repeat;
  background-position-x: center;
}

#contact-out{
  width: 100%;
  padding-top:200px;
  padding-bottom:200px;
  background-repeat: no-repeat;
  background-position-x: center;
  max-width: 1440px;
}

/* ProductPhotosField.css */

.product-photos-container {
  margin-top: 1.5rem;
  max-width: 600px;
  margin: auto
}

.product-photos-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #374151;
}

.product-photos-dropzone {
  position: relative;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 200px;
  border-radius: 0.375rem;
  border: 2px dashed #6b7280;
}

.product-photos-dropzone.active {
  border-color: #4f46e5;
}

.product-photos-files {
  height: fit-content;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(4, 100px);/* Adjust the minmax value as needed */
  gap: 30px;
}

@media screen and (max-width: 580px) {
  .product-photos-files{
    grid-template-columns: repeat(3, 100px);
  }
}
@media screen and (max-width: 440px) {
  .product-photos-files{
    grid-template-columns: repeat(2, 100px);
  }
}

.product-photos-file-container {
  position: relative;
  margin-bottom: 0.5rem;
  width: 100px;
}

.product-photos-remove-button {
  width: 35px;
  height: 35px;
  position: absolute;
  z-index: 2;
  right: 5px;
  top: 5px;
  background-color: #6b7280;
  border-radius: 50%;
  padding: 0.25rem;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.product-photos-size-indicator {
  position: absolute;
  bottom: -0.3125rem;
  right: -0.3125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: #f3f4f6;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  color: #6b7280;
}

.product-photos-image-link {
  display: block;
}

.product-photos-image {
  display: block;
  max-width: 7rem;
  min-width: 7rem;
  height: 7rem;
  min-height: 7rem;
  object-fit: cover;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.25s, filter 0.25s;
}

.product-photos-image:hover {
  opacity: 0.75;
  filter: brightness(0.75);
}

.product-photos-info {
  position: relative;
  bottom: 40px;
  left: 0;
  width: 100%;
  height: 130px;
  border-radius: 20px;
  background-color: #f3f4f6;
  display: block;
  align-items: center;
  justify-content: space-between;
}

.product-photos-info-left {
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 10px;
}

.product-photos-info-right {
  font-size: 0.75rem;
  color: #6b7280;
}

.product-photos-info-button {
  width: 120px;
  height: 50px;
  margin-top: 20px;
  margin-left: 10px;
  padding: 20px ;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #FF4C00;
  cursor: pointer;
  display: flex;
  align-items: center;
  
}

.product-photos-button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f3f4f6;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.product-photos-button {
  font-size: 0.875rem;
  font-weight: 600;
  color: #FF4C00;
  cursor: pointer;
}

.product-photos-button:hover {
  text-decoration: underline;
}

.product-photos-button:hover .product-photos-button-label {
  color: #FF4C00;
}

.product-photos-button-label {
  margin-right: 0.25rem;
}


.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttons-out {
  margin: auto 10px;
}
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  box-shadow: -20px 0px,20px 0px;
  animation: l18 1s infinite;
  position: relative;
  top:30vh;
  left: 50vw;
  transform: translate(-50%);
}
@keyframes l18{ 
  25%  {box-shadow: -20px -20px, 20px 20px}
  50%  {box-shadow:   0px -20px,  0px 20px}
  75%  {box-shadow:  20px -20px,-20px 20px}
  100% {box-shadow:  20px   0px,-20px  0px}
}

.dropdown-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease, opacity 0.8s ease;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  overflow:visible;
  top: 20px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px;
  text-decoration: none;
  display: none;
  text-align: left;
  overflow:visible;
  transition: display 0.8s ease;
}

.dropdown-content a:hover {
  background-color: #ddd;
  overflow:visible;
}

.dropdown:hover .dropdown-content {
  opacity: 1;
  max-height: 500px;
  transition: max-height 0.8s ease, opacity 0.8s ease;
}

.dropdown:hover .dropdown-content a {
  display: block;
  max-height: 500px;
  transition: max-height 0.4s ease, opacity 0.4s ease;
}

.menu-outer, .menu, .center-div, .info-big-container, .info-container {
  overflow: visible;
  position: relative; /* Ensures z-index works as expected */
}

.barlow-condensed-bold {
  font-family: "Barlow Condensed", serif;
  font-weight: 700;
  font-style: normal;
}

.open-sans {
  font-family: "Open Sans", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}