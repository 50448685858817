.slider {
    position: relative;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    left:50%;
    transform: translate(-50%);
    max-width: 900px;
  }
  
  .slider-text{
    color: black;
    font-size: 35px;
    line-height: 50px;
    text-align: center;
  }


  .slider-text-container{
    width:  95%;
    margin: auto;
  }
  
  .image-slider {
    width: 1000px;
    height: 600px;
    border-radius: 10px;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 0px;
    font-size: 3rem;
    color: #000;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    width: 55px;
    height: 55px;
    background-image: url(./assets/desktop/right_arrow.png);
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 0px;
    font-size: 3rem;
    color: #000;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    width: 55px;
    height: 55px;
    background-image: url(./assets/desktop/left_arrow.png);
  }

  @media screen and (max-width:800px) {
    .slider-text{
      font-size: 18px;
      line-height: 30px;
    }

    .slider-text-container{
        width: 60%;
        min-width: 223px;
    }
  }

@media screen and (max-width:550px) {
  .left-arrow{
    width: 35px;
    height: 35px;
    background-size: cover;
    left: 10px;
  }
  .right-arrow{
    width: 35px;
    height: 35px;
    background-size: cover;
    right: 10px;
  }
  
}

  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }


  #dot1{
    background-image: url(../public/images/dots1.png);
  }
  #dot2{
    background-image: url(../public/images/dots2.png);
  }
  #dot3{
    background-image: url(../public/images/dots3.png);
  }
  #dot4{
    background-image: url(../public/images/dots4.png);
  }
  #dot5{
    background-image: url(../public/images/dots5.png);
  }
  #dot6{
    background-image: url(../public/images/dots6.png);
  }
  #dot7{
    background-image: url(../public/images/dots7.png);  
  }
  #dot8{
    background-image: url(../public/images/dots8.png); 
  }
  
  .dots{
    display: block;
    height: 12px;
    width: 236px;
    margin: auto;
    margin-top: 20px;
  }